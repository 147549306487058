import esLocale from 'date-fns/locale/es';
import { addHours, format } from 'date-fns';

export function namedDate(date) {
    const named = format(date, `EEEE, d 'de' MMMM 'de' yyyy `, { locale: esLocale });

    return named;
}

export function namedDateFull(date) {
    const newDate = addHours(new Date(date), 1);
    //console.log(newDate);
    const named = format(newDate, `EEEE d 'de' MMMM 'de' yyyy, H:mm:ss `, { locale: esLocale });

    return named;
}

export function namedDateFullPunteo(date) {
    const newDate = new Date(date);
    console.log(newDate);
    const named = format(newDate, `EEEE d 'de' MMMM 'de' yyyy, H:mm:ss `, { locale: esLocale });
    console.log(named);

    return named;
}

export function namedDateShort(date) {
    const named = format(date, `d 'de' MMMM  yyyy `, { locale: esLocale });

    return named;
}

export function getFecha(date) {
    const fecha = format(date, `yyyy-MM-dd`);

    return fecha;
}

export function getFechaPrint(date) {
    const fecha = format(date, `dd/MM/yyyy`);

    return fecha;
}
export function getDay(date) {
    const fecha = format(date, `d`);

    return fecha;
}
export function getMonthAbr(date) {
    const fecha = format(date, `LLL`);

    return fecha;
}

export function namedStringDate(date) {
    const sp = date.split('-');
    const fecha = new Date(sp[0], parseInt(sp[1]) - 1, sp[2]);
    const named = format(fecha, `EEEE, d 'de' MMMM 'de' yyyy `, { locale: esLocale });

    return named;
}

export function namedBloque(bloque) {
    let nbloque = '';
    if (bloque == 1) {
        nbloque = '10:00am - 3:00pm';
    } else if (bloque == 2) {
        nbloque = '3:00pm - 9:00pm';
    }

    return nbloque;
}

export function namedMetodo(metodo) {
    let nmet = '';
    if (metodo == 1) {
        nmet = 'Efectivo';
    } else if (metodo == 2) {
        nmet = 'Transferencia/Depósito';
    }

    return nmet;
}
