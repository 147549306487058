// third-party
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

// assets
import {
    IconDashboard,
    IconDeviceAnalytics,
    IconFileExport,
    IconListDetails,
    IconSquarePlus,
    IconPrinter,
    IconListCheck,
    IconSearch,
    IconBarcode,
    IconRoad,
    IconPackgeImport,
    IconMap,
    IconMap2
} from '@tabler/icons';

import DatabaseIcon from '../ui-component/icons/DatabaseIcon';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconFileExport,
    IconListDetails,
    IconSquarePlus,
    AddCircleOutlineIcon,
    IconPrinter,
    IconListCheck,
    IconSearch,
    IconBarcode,
    IconRoad,
    IconPackgeImport,
    IconMap,
    IconMap2
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const recibo = {
    id: 'recibo',
    title: <Typography variant="body">Recibo</Typography>,
    type: 'group',
    children: [
        {
            id: 'reporteDespacho',
            title: <Typography variant="body">Reporte despacho</Typography>,
            type: 'item',
            url: '/repdespacho',
            icon: icons.IconListDetails,
            breadcrumbs: false
        },
        {
            id: 'routes',
            title: <Typography variant="body">Recorridos</Typography>,
            type: 'item',
            url: '/recorridos',
            icon: icons.IconRoad,
            breadcrumbs: false
        }
    ]
};

export default recibo;
