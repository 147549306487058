import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import BlankRoutes from './BlankRoutes';
import OpsRoutes from './OpsRoutes';
import AdminRoutes from './AdminRoutes';
import useAuth from 'hooks/useAuth';
import ReciboRoutes from './ReciboRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { isLoggedIn, user, logged, setLogged } = useAuth();
    console.log('routes');
    console.log(user);
    if (user) {
        if (user.roleId == 0) {
            return useRoutes([MainRoutes, LoginRoutes, BlankRoutes, AuthenticationRoutes]);
        } else if (user.roleId == 3) {
            return useRoutes([OpsRoutes, LoginRoutes, BlankRoutes, AuthenticationRoutes]);
        } else if (user.roleId == 1) {
            console.log('admin routes');
            return useRoutes([AdminRoutes, MainRoutes, OpsRoutes, LoginRoutes, BlankRoutes, AuthenticationRoutes]);
        } else if (user.roleId == 4) {
            console.log('recibo routes');
            return useRoutes([ReciboRoutes, LoginRoutes, BlankRoutes, AuthenticationRoutes]);
        }
    } else {
        return useRoutes([AdminRoutes, MainRoutes, OpsRoutes, LoginRoutes, BlankRoutes, AuthenticationRoutes]);
    }
    //return useRoutes([OpsRoutes, LoginRoutes, BlankRoutes, AuthenticationRoutes]);
}
