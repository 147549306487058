import { lazy } from 'react';
import ReciboLayout from 'layout/ReciboLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const RecorridosMain = Loadable(lazy(() => import('views/operaciones/Recorridos/RecorridosMain')));
const ReporteDespacho = Loadable(lazy(() => import('views/operaciones/ReporteDespacho/ReporteDespachoPage')));

const ReciboRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <ReciboLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <ReporteDespacho />
        },
        {
            path: '/repdespacho',
            element: <ReporteDespacho />
        },
        {
            path: '/recorridos',
            element: <RecorridosMain />
        },
        {
            path: '/recorridos/:id',
            element: <RecorridosMain />
        }
    ]
};

export default ReciboRoutes;
